import React from 'react';
import { Routes, Route } from 'react-router-dom';
import SignInPage from './Components/SigninpageComponent';
import HomePageComponent from './Components/HomePageComponent';
import Dashboard from './Components/DashboardComponent';
import CurrentPlansComponent from './Components/PlansComponents/CurrentPlansComponent';
import AddPlansComponent from './Components/PlansComponents/AddPlansComponent';
import InvestmentsComponent from './Components/InvestmentsComponent';
import MonthlyReturns from './Components/MonthlyreturnsComponent';
import AddBankcomponent from './Components/AddBankComponent';
import Withdrawn from './Components/WithdrawnComponent';
import AppUsers from './Components/UserlistComponent';
import FeedbackForm from './Components/FeedbackComponent';
import FrequentlyQuestions from './Components/AskedquestionsComponent';
import Howtouse from './Components/Howtouse';
import Knowmoreaboutlending from './Components/PlansComponents/Knowmoreaboutlending'
import ContactdetailsComponent from './Components/ContactdetailsComponent';
import Privacypolicy from './Components/PrivacypolicyComponent';
import Referralscreen from './Components/ReferralComponent';
const App = () => {
  return (
    <Routes>
      <Route path="/" element={<SignInPage />} />
      <Route path="/home" element={<HomePageComponent />}>
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="plans/current" element={<CurrentPlansComponent />} />
        <Route path="plans/add" element={<AddPlansComponent />} />
        <Route path="Userlist" element={<AppUsers />} />
        <Route path="investments" element={<InvestmentsComponent />} />
        <Route path="monthlyreturns" element={<MonthlyReturns />} />
        <Route path="AddBank" element={<AddBankcomponent />} />
        <Route path="Howtouse" element={<Howtouse />} />
        <Route path="withdrawn" element={<Withdrawn />} />  
        <Route path="feedback" element={<FeedbackForm />} />
        <Route path="frequentlyQuestions" element={<FrequentlyQuestions />} />
        <Route path ='Knowmore' element={<Knowmoreaboutlending/>}/>
        <Route path='contactdetails' element={<ContactdetailsComponent/>}/>
        <Route path='privacypolicy' element={<Privacypolicy/>}/>
        <Route path='referralscreen' element={<Referralscreen/>}/>
      </Route>
    </Routes>
  );
};

export default App;
