import React, { useEffect, useState } from 'react';
import { Form, Input, Button, List, message, Card } from 'antd';
import axios from 'axios';

const ContactForm = () => {
  const [form] = Form.useForm();
  const [contacts, setContacts] = useState([]);
  const [editingContact, setEditingContact] = useState(null);
  const hosturl = 'https://api.awgfinserv.com';

  // Function to fetch contacts
  const fetchContacts = async () => {
    try {
      const response = await axios.get(`${hosturl}/api/contacts`);
      setContacts(response.data);
      console.log("contacts", setContacts)
    } catch (error) {
      message.error('Failed to fetch contacts. Please try again.');
      console.error('Fetch contacts error:', error.response ? error.response.data : error);
    }
  };

  // useEffect to fetch contacts on mount
  useEffect(() => {
    fetchContacts();
  }, []);

  // Function to handle form submission
  const onFinish = async (values) => {
    try {
      if (editingContact) {
        await axios.put(`${hosturl}/api/contacts/${editingContact._id}`, values);
        message.success('Contact updated successfully!');
        setEditingContact(null);
      } else {
        await axios.post(`${hosturl}/api/contacts`, values);
        message.success('Contact added successfully!');
      }
      fetchContacts(); // Fetch updated contact list
      form.resetFields(); // Reset the form
    } catch (error) {
      message.error('Failed to save contact. Please try again.');
      console.error(error);
    }
  };

  // Function to delete a contact
  const deleteContact = async (id) => {
    try {
      await axios.delete(`${hosturl}/api/contacts/${id}`);
      message.success('Contact deleted successfully!');
      fetchContacts(); // Fetch updated contact list
    } catch (error) {
      message.error('Failed to delete contact. Please try again.');
      console.error(error);
    }
  };

  // Function to edit a contact
  const editContact = (contact) => {
    setEditingContact(contact);
    form.setFieldsValue(contact);
  };

  return (
    <div style={styles.container}>
      <Card style={styles.card} title="Contact Management" bordered={false}>
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Form.Item name="email" label="Email" rules={[{ required: true, type: 'email' }]}>
            <Input placeholder="Enter your email" />
          </Form.Item>
          <Form.Item name="phone" label="Phone Number" rules={[{ required: true }]}>
            <Input placeholder="Enter your phone number" />
          </Form.Item>
          <Form.Item name="whatsappnumber" label="WhatsApp Number" rules={[{ required: true }]}>
            <Input placeholder="Enter your WhatsApp number" />
          </Form.Item>
          <Button type="primary" htmlType="submit" style={styles.submitButton}>
            {editingContact ? 'Update Contact' : 'Add Contact'}
          </Button>
        </Form>

        <List
          itemLayout="horizontal"
          dataSource={contacts}
          renderItem={(contact) => (
            <List.Item
              actions={[
                <Button onClick={() => editContact(contact)}>Edit</Button>,
                <Button onClick={() => deleteContact(contact._id)} danger>
                  Delete
                </Button>,
              ]}
              style={styles.listItem}
            >
              <List.Item.Meta title={contact.email} description={`${contact.phone} | ${contact.whatsappnumber}`} />
            </List.Item>
          )}
          style={styles.contactList}
        />
      </Card>
    </div>
  );
};

const styles = {
  container: {
    padding: '20px',
    backgroundColor: '#f5f6fa',
    minHeight: '100vh',
    overflowY:'auto '
  },
  card: {
    width: '100%',
    maxWidth: '600px',
    margin: '20px auto',
    borderRadius: '10px',
    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
  },
  submitButton: {
    width: '100%',
    borderRadius: '5px',
  },
  contactList: {
    marginTop: '20px',
  },
  listItem: {
    borderBottom: '1px solid #f0f0f0',
  },
};

export default ContactForm;
