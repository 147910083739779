import React, { useEffect, useState } from 'react';
import {
  Table,
  Button,
  Modal,
  Form,
  Input,
  message,
  Space,
  Spin,
  Layout,
  Card,
} from 'antd';
import { EditOutlined, DeleteOutlined, DownloadOutlined } from '@ant-design/icons';

const { Header, Content } = Layout;
const hosturl = 'https://api.awgfinserv.com';

function AppUsers() {
  const [purchasedUsers, setPurchasedUsers] = useState([]);
  const [nonPurchasedUsers, setNonPurchasedUsers] = useState([]);
  const [showPurchased, setShowPurchased] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [userData, setUserData] = useState({
    _id: '',
    username: '',
    phoneNumber: '',
    address: {
      houseNo: '',
      area: '',
      pincode: '',
    },
    bankaccount: {
      bankName: '',
      accountHolderName: '',
      accountNumber: '',
      ifscCode: '',
    },
    dob: '',
    additionalFields: [],
  });
  const [loading, setLoading] = useState(false);
  const [searchInput, setSearchInput] = useState('');

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${hosturl}/api/app-users-with-purchase-status`);
      const data = await response.json();
      setPurchasedUsers(data.purchasedUsers);
      setNonPurchasedUsers(data.nonPurchasedUsers);
    } catch (error) {
      console.error('Error fetching data:', error);
      message.error('Failed to fetch user data.');
    } finally {
      setLoading(false);
    }
  };

  const handleToggle = (isPurchased) => {
    setShowPurchased(isPurchased);
  };

  const handleDownload = () => {
    const usersToDownload = showPurchased ? purchasedUsers : nonPurchasedUsers;
    const title = showPurchased ? 'Purchased Users List' : 'Non-Purchased Users List';

    const csvContent = [
      [title],
      [], // Empty line
      ['S.No', 'Username', 'Mobile Number'],
      ...usersToDownload.map((user, index) => [index + 1, user.username, user.phoneNumber]),
    ]
      .map(row => row.join(','))
      .join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', 'users.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleEdit = (user) => {
    setUserData({
      _id: user._id,
      username: user.username || '',
      phoneNumber: user.phoneNumber || '',
      address: user.address || {
        houseNo: '',
        area: '',
        pincode: '',
      },
      bankaccount: user.bankaccount || {
        bankName: '',
        accountHolderName: '',
        accountNumber: '',
        ifscCode: '',
      },
      dob: user.dob ? user.dob.split('T')[0] : '',
      additionalFields: user.additionalFields || [],
    });
    setIsEditing(true);
  };

  const handleDelete = async (userId) => {
    if (window.confirm('Are you sure you want to delete this user?')) {
      try {
        await fetch(`${hosturl}/api/users/${userId}`, { method: 'DELETE' });
        message.success('User deleted successfully');
        fetchData();
      } catch (error) {
        console.error('Error deleting user:', error);
        message.error('Failed to delete user.');
      }
    }
  };

  const handleDeleteAdditionalField = async (index) => {
    const fieldToRemove = userData.additionalFields[index];
    if (window.confirm('Are you sure you want to delete this additional detail?')) {
      try {
        const response = await fetch(`${hosturl}/api/delete-additional-field/${userData._id}/${fieldToRemove._id}`, {
          method: 'DELETE',
        });
        
        if (response.ok) {
          const updatedFields = userData.additionalFields.filter((_, i) => i !== index);
          setUserData({ ...userData, additionalFields: updatedFields });
          message.success('Additional detail removed successfully.');
        } else {
          const errorData = await response.json();
          message.error(`Failed to delete additional detail: ${errorData.message}`);
        }
      } catch (error) {
        console.error('Error deleting additional detail:', error);
        message.error('An error occurred while deleting the additional detail.');
      }
    }
  };

  const handleUpdate = async () => {
    if (!userData.username || !userData.phoneNumber) {
      message.error('Username and Phone Number are required.');
      return;
    }

    try {
      const response = await fetch(`${hosturl}/update-profile`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          phoneNumber: userData.phoneNumber,
          username: userData.username,
          dob: userData.dob,
          address: JSON.stringify(userData.address || {}),
          bankaccount: JSON.stringify(userData.bankaccount || {}),
          additionalFields: JSON.stringify(userData.additionalFields || []),
        }),
      });

      if (response.ok) {
        message.success('Profile updated successfully');
        fetchData();
        setIsEditing(false);
        setUserData({
          _id: '',
          username: '',
          phoneNumber: '',
          address: {
            houseNo: '',
            area: '',
            pincode: '',
          },
          bankaccount: {
            bankName: '',
            accountHolderName: '',
            accountNumber: '',
            ifscCode: '',
          },
          dob: '',
          additionalFields: [],
        });
      } else {
        const errorData = await response.json();
        message.error(`Failed to update user: ${errorData.message}`);
      }
    } catch (error) {
      console.error('Error updating user:', error);
      message.error('An error occurred while updating the profile.');
    }
  };

  const handleAddField = () => {
    setUserData(prevState => ({
      ...prevState,
      additionalFields: [...prevState.additionalFields, { name: '', dob: '', occasion: '', relation: '' }],
    }));
  };

  const handleFieldChange = (index, key, value) => {
    const updatedFields = [...userData.additionalFields];
    updatedFields[index][key] = value;
    setUserData({ ...userData, additionalFields: updatedFields });
  };

  const filteredUsers = (showPurchased ? purchasedUsers : nonPurchasedUsers).filter(user =>
    user.username.toLowerCase().includes(searchInput.toLowerCase()) ||
    user.phoneNumber.includes(searchInput)
  );

  return (
    <Layout>
      <Header style={styles.header}>
        <h1 style={styles.heading}>App Users</h1>
      </Header>
      <Content style={styles.content}>
        <Card>
          <Input
            placeholder="Search by Username or Phone Number"
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
            style={styles.searchInput}
          />
          <Space style={styles.buttonContainer}>
            <Button
              type={showPurchased ? 'primary' : 'default'}
              onClick={() => handleToggle(true)}
              style={styles.toggleButton}
            >
              Purchased Users
            </Button>
            <Button
              type={!showPurchased ? 'primary' : 'default'}
              onClick={() => handleToggle(false)}
              style={styles.toggleButton}
            >
              Non-Purchased Users
            </Button>
            <Button type="primary" onClick={handleDownload} icon={<DownloadOutlined />}>
              Download
            </Button>
          </Space>

          {loading ? (
            <Spin size="large" />
          ) : (
            <div style={styles.tableContainer}>
              <Table
                dataSource={filteredUsers}
                rowKey="_id"
                pagination={false}
                style={styles.table}
                rowClassName={(record, index) => (index % 2 === 0 ? 'even-row' : 'odd-row')}
              >
                <Table.Column title="S.No" render={(text, record, index) => index + 1} align="center" />
                <Table.Column title="Username" dataIndex="username" render={(text) => text || 'N/A'} align="center" />
                <Table.Column title="Phone Number" dataIndex="phoneNumber" render={(text) => text || 'N/A'} align="center" />
                <Table.Column
                  title="Actions"
                  render={(text, user) => (
                    <Space>
                      <Button icon={<EditOutlined />} onClick={() => handleEdit(user)}>Edit</Button>
                      <Button danger icon={<DeleteOutlined />} onClick={() => handleDelete(user._id)}>Delete</Button>
                    </Space>
                  )}
                  align="center"
                />
              </Table>
            </div>
          )}

          <Modal
            title="Edit User"
            visible={isEditing}
            onOk={handleUpdate}
            onCancel={() => setIsEditing(false)}
            okText="Save"
          >
            <Form layout="vertical">
              <Form.Item label="Username" style={{ textAlign: 'center' }}>
                <Input
                  value={userData.username}
                  onChange={(e) => setUserData({ ...userData, username: e.target.value })}
                />
              </Form.Item>
              <Form.Item label="Phone Number">
                <Input
                  value={userData.phoneNumber}
                  onChange={(e) => setUserData({ ...userData, phoneNumber: e.target.value })}
                />
              </Form.Item>
              <Form.Item label="Address - House No">
                <Input
                  value={userData.address.houseNo}
                  onChange={(e) => setUserData({ ...userData, address: { ...userData.address, houseNo: e.target.value } })}
                />
              </Form.Item>
              <Form.Item label="Address - Area">
                <Input
                  value={userData.address.area}
                  onChange={(e) => setUserData({ ...userData, address: { ...userData.address, area: e.target.value } })}
                />
              </Form.Item>
              <Form.Item label="Pincode">
                <Input
                  value={userData.address.pincode}
                  onChange={(e) => setUserData({ ...userData, address: { ...userData.address, pincode: e.target.value } })}
                />
              </Form.Item>
              <Form.Item label="Bank Name">
                <Input
                  value={userData.bankaccount.bankName}
                  onChange={(e) => setUserData({ ...userData, bankaccount: { ...userData.bankaccount, bankName: e.target.value } })}
                />
              </Form.Item>
              <Form.Item label="Account Holder Name">
                <Input
                  value={userData.bankaccount.accountHolderName}
                  onChange={(e) => setUserData({ ...userData, bankaccount: { ...userData.bankaccount, accountHolderName: e.target.value } })}
                />
              </Form.Item>
              <Form.Item label="Account Number">
                <Input
                  value={userData.bankaccount.accountNumber}
                  onChange={(e) => setUserData({ ...userData, bankaccount: { ...userData.bankaccount, accountNumber: e.target.value } })}
                />
              </Form.Item>
              <Form.Item label="IFSC Code">
                <Input
                  value={userData.bankaccount.ifscCode}
                  onChange={(e) => setUserData({ ...userData, bankaccount: { ...userData.bankaccount, ifscCode: e.target.value } })}
                />
              </Form.Item>
              <Form.Item label="Date of Birth">
                <Input
                  type="date"
                  value={userData.dob}
                  onChange={(e) => setUserData({ ...userData, dob: e.target.value })}
                />
              </Form.Item>
              {userData.additionalFields.map((field, index) => (
                <Space key={index} style={{ marginBottom: 8 }} align="baseline">
                  <Input
                    placeholder="Name"
                    value={field.name}
                    onChange={(e) => handleFieldChange(index, 'name', e.target.value)}
                  />
                  <Input
                    type="date"
                    value={field.dob}
                    onChange={(e) => handleFieldChange(index, 'dob', e.target.value)}
                  />
                  <Input
                    placeholder="Occasion"
                    value={field.occasion}
                    onChange={(e) => handleFieldChange(index, 'occasion', e.target.value)}
                  />
                  <Input
                    placeholder="Relation"
                    value={field.relation}
                    onChange={(e) => handleFieldChange(index, 'relation', e.target.value)}
                  />
                  <Button
                    type="link"
                    danger
                    onClick={() => handleDeleteAdditionalField(index)}
                  >
                    Remove
                  </Button>
                </Space>
              ))}

              <Button onClick={handleAddField} type="dashed" style={{ marginBottom: 16 }}>
                Add Fields
              </Button>
            </Form>
          </Modal>
        </Card>
      </Content>
    </Layout>
  );
}

const styles = {
  header: {
    backgroundColor: 'white',
    color: 'black',
    padding: '20px 0',
    // textAlign: 'center',
  },
  heading: {
    fontSize: '1.4rem',
    margin: '0',
  },
  content: {
    padding: '20px',
    overflowY: 'auto',
    height: 'calc(100vh - 64px)',
  },
  searchInput: {
    marginBottom: '20px',
    width: '100%',
    borderRadius: '8px',
    boxShadow: '0 1px 4px rgba(0, 0, 0, 0.1)',
  },
  buttonContainer: {
    marginBottom: '20px',
    display: 'flex',
    // justifyContent: 'space-between',
  },
  toggleButton: {
    margin: '0 10px',
  },
  tableContainer: {
    maxHeight: '400px', // Adjust this value as needed
    overflowY: 'auto',
    borderRadius: '8px',
    boxShadow: '0 1px 4px rgba(0, 0, 0, 0.1)',
  },
  table: {
    background: '#fff',
    borderRadius: '8px',
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
  },
};

export default AppUsers;
